import ApiClient, {IncrementedPipe} from "../Api/ApiClient";
import Button from "react-bootstrap/Button";
import {faTrashCan, faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ActionFunctionArgs, Form, redirect} from "react-router-dom";
import {z} from "zod";
import LabelsClient from "../Api/LabelsClient";

export async function movePipe({params} : ActionFunctionArgs) {
    let pass = z.enum(["pass","scrap"]).parse(params.action);
    let eventId = z.string().parse(params.eventId!);

    let response = await new ApiClient().MoveHoldPipe(eventId, pass === "pass");

    await new LabelsClient().PrintLabel(response.EventIdCompact); //Print new label
    return redirect(`/p/${response.EventIdCompact}`); //Redirect to the new pipe
}

export default function QCMove(props: {pipe: IncrementedPipe}) {
    let pipe = props.pipe;
    let qty = pipe.Qty;
    if (!qty || qty.Pass) return null;

    let msg = qty.Quantity > 1 ? ` ${qty.Quantity} pipes` : null;

    return (
        <Form method="POST" style={{display: "flex" }} className="qc-buttons-wrapper">
            <Button variant="success"
                    type="submit"
                    formAction="pass">
                <FontAwesomeIcon icon={faCheckCircle} size="lg"/>
                <span>Pass{msg}</span>
            </Button>
            <Button variant="danger"
                    type="submit"
                    formAction="scrap">
                <FontAwesomeIcon icon={faTrashCan} size="lg"/>
                <span>Scrap{msg}</span>
            </Button>
        </Form>
    );
}