import HttpClient from "../Framework/HttpClient";
import ApiConfiguration from "./Configuration";

import * as Exo from './ExoClient'

import {z} from "zod";

const lineSchema = z.object({
    Id: z.string(),
    Name: z.string(),
    Factory: z.object({
        Name: z.string()
    })
})

const jobSchema = z.object({
    Id: z.string(),
    CreatedOn: z.coerce.date(),
    DueDate: z.coerce.date(),
    SKU: z.object({
        Stockcode: z.string(),
        Description: z.string(),
        Length: z.number(),
        IsCoil: z.boolean(),
        Product: z.object({
            Id: z.string(),
            Description: z.string(),
            Material: z.object({
                Id: z.string(),
                Name: z.string(),
                Code: z.string(),
                SKUDescription: z.object({
                    Hide: z.boolean()
                })
            })
        })
    }),
    CustomerName: z.string().nullish(),
    SalesOrder: Exo.ExoSalesOrderSchema.nullish(),
    SalesOrderLine: Exo.ExoSalesOrderLineSchema.nullish(),
    WorksOrder: Exo.ExoWorksOrderSchema.nullish()
});

const incrementedPipeSchema = z.object({
    EventId: z.number(),
    EventIdCompact: z.string(),
    Timestamp: z.coerce.date(),
    Deleted: z.boolean(),

    Job: jobSchema,
    Line: lineSchema,
    JobSetup: z.object({
        NestedCoil: z.boolean(),
        BundleSize: z.number().nullish(),
        ShortBatchNo: z.string(),
        FullBatchNo: z.string()
    }),

    Qty: z.object({
        Quantity: z.number(),
        Pass: z.boolean(),
        Label: z.object({
            QtyDescriptions: z.string().array()
        }).nullish(),
    }).nullish(),

    ScrapKilos: z.number().nullish()
});

const processIncrementedPipeResponseSchema = z.object({
    EventIdCompact: z.string()
});

export type Line = z.infer<typeof lineSchema>;
export type Job = z.infer<typeof jobSchema>;
export type IncrementedPipe = z.infer<typeof incrementedPipeSchema>;
export type ProcessIncrementedPipeResponse = z.infer<typeof processIncrementedPipeResponseSchema>;

export default class ApiClient extends HttpClient {
    constructor() {
        super(ApiConfiguration.Api);
    }

    public async GetPipe(eventId: string) : Promise<IncrementedPipe> {
        return await this.Get(`api/Schedule/IncrementedPipes/${eventId}`, incrementedPipeSchema) as IncrementedPipe;
    }

    public async MoveHoldPipe(eventId:string, pass: boolean) {
        let pipe = await this.GetPipe(eventId);
        let body = {
            Job: pipe.Job.Id,
            Quantity: pipe.Qty?.Quantity,
            Pass: pass,
            SourceEventId: pipe.EventId
        }
        return await this.Send("POST",
            `api/Schedule/${pipe.Line.Id}/MoveHoldPipe`,
            body,
            processIncrementedPipeResponseSchema) as ProcessIncrementedPipeResponse;
    }
}