
import {
    AccountInfo,
    AuthenticationResult, BrowserCacheLocation,
    Configuration,
    EventMessage,
    EventType, InteractionRequiredAuthError,
    PublicClientApplication
} from "@azure/msal-browser";

import 'bootstrap/dist/css/bootstrap.min.css';
import msalClientApp from "./MsalClientApp";

//Setup MSAL
const conf : Configuration = {
    auth: {
        clientId: "af227a97-db6b-4ffb-bd44-b866e269b6b6",
        authority: "https://login.microsoftonline.com/88e96ab6-6a86-4cc4-9ff1-055e45fd1d3a/v2.0",
        redirectUri: "/",
        postLogoutRedirectUri: "/",
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage //Persist login for every scan
    }
}

export const MsalScopes = [`api://${conf.auth.clientId}/Api`];

//Acquires a token silently if possible, otherwise acquires a token interactively
async function AcquireToken(app: PublicClientApplication, account: AccountInfo) {
    const request = {
        scopes: MsalScopes,
        account: account
    };
    try {
        await app.acquireTokenSilent(request);
    }
    catch (e) {
        if (e instanceof InteractionRequiredAuthError) {
            //Interaction required

            //This causes a redirect, hence never returns
            await app.acquireTokenRedirect({
                scopes: MsalScopes,
                account: account
            });
            throw new Error("Redirected to acquire token");
        }

        //Unknown error, re-throw
        throw e;
    }
}

const MsalClientApp = new PublicClientApplication(conf);

export default MsalClientApp;

export async function InitializeMsal(): Promise<PublicClientApplication>
{
    await MsalClientApp.initialize();

    //On login, set the active account to the account that logged in
    MsalClientApp.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            MsalClientApp.setActiveAccount(account);
        }
    });

    //Check if there is a token in the cache
    let account = msalClientApp.getActiveAccount();
    if (account) {
        await AcquireToken(MsalClientApp, account);
        return MsalClientApp;
    }

    let result = await MsalClientApp.handleRedirectPromise();

    if (result?.account)
    {
        //If there is an account, ensure we can acquire a token silently
        await AcquireToken(MsalClientApp, result.account);
    }
    else {
        // User is not logged in, redirect to login page
        await MsalClientApp.loginRedirect({scopes: MsalScopes});
        throw new Error("Redirected to login page");

    }

    return MsalClientApp;
}