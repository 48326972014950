import {IncrementedPipe} from "../Api/ApiClient";
import ApiClient from "../Api/ApiClient";
import {Table} from "react-bootstrap";
import QCMove from "./QCMove";

import {LoaderFunctionArgs, useLoaderData} from "react-router-dom";

interface PipeData {
    pipe: IncrementedPipe,
    index: number | null
}

export async function loadPipe({ request, params } : LoaderFunctionArgs) {
    let pipe = await new ApiClient().GetPipe(params.eventId!);
    let index = new URL(request.url).searchParams.get("i");
    return {
        pipe,
        index: index ? parseInt(index) : null
    } as PipeData;
}

export default function PipeView() {
    //TODO: redirect to new pipe (from results)

    let {pipe, index} = useLoaderData() as PipeData;

    let props: {[index:string]:any} = {
        Date: pipe.Timestamp.toDisplayDateTimeString(),
        Factory: pipe.Line.Factory.Name,
        Line: pipe.Line.Name,
        Kilos: pipe.ScrapKilos?.toLocaleString(),
        //"Account No": customer?.AccountNo,

        //Coil labels are printed individually
        //Show quantity if qty > 1 and not a coil
        "Quantity": pipe.Qty && pipe.Qty.Quantity > 1 && !pipe.Job.SKU.IsCoil ? pipe.Qty?.Quantity : null,
        "Nested Coil": pipe.JobSetup.NestedCoil ? "Yes" : null,

        //TODO: For senior staff, show full customer name
        Customer: pipe.Job.CustomerName,
        "Sales Order": pipe.Job.SalesOrderLine?.Order?.Number
            ?? pipe.Job.SalesOrder?.Number
            ?? pipe.Job.WorksOrder?.SalesOrder?.Number,
        "Works Order": pipe.Job.WorksOrder?.Number,
        "Batch No": pipe.JobSetup.FullBatchNo,
        "Stock Code": pipe.Job.SKU.Stockcode,
        Description: pipe.Job.SKU.Description,
        Material: pipe.Job.SKU.Product.Material.SKUDescription.Hide ? null : pipe.Job.SKU.Product.Material.Code
    }
    
    let list : JSX.Element[] = [];
    let i = 0;
    for (let prop in props) {
        if (!props[prop]) continue;
        list.push(
            <tr key={i++}>
                <td><b>{prop}</b></td>
                <td>{props[prop]}</td>
            </tr>);
    }


    return (
        <div key={pipe.EventIdCompact}>
            {pipe.Deleted ? <h1 className={"alert alert-danger"}>Increment Deleted</h1> : null }
            <div>
                {pipe.Qty != null && !pipe.Qty.Pass ? <h1>Hold</h1> : null}
                {pipe.ScrapKilos != null ? <h1>Scrap</h1> : null}
                <Table borderless>
                    <tbody>
                    {list}
                    </tbody>
                </Table>
                {pipe.Qty?.Label != null && index != null
                    ? <h1 style={{marginBottom: "1em"}}>{pipe.Qty.Label.QtyDescriptions[index]}</h1>
                    : null}
                <QCMove pipe={pipe} />

            </div>
        </div>
    );
}