import HttpClient from "../Framework/HttpClient";
import ApiConfiguration from "./Configuration";

export default class LabelsClient extends HttpClient {
    constructor() {
        super(ApiConfiguration.Labels);
    }

    public async PrintLabel(eventId: string) {
        return this.SendCore("POST", `api/Labels/Print/${eventId}`, null);
    }
}